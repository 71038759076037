<template>
  <div class="report">
    <!-- 报表 -->
    <div class="mark">
      <p class="top">{{detail.storeName}} {{detail.date}}</p>
      <div class="list">
        <p class="title">进店人流量</p>
        <p class="content">{{detail.peopleNum}}</p>
      </div>
      <div class="list">
        <p class="title">今日销售</p>
        <p class="content">¥ {{detail.salesToday}}</p>
      </div>
      <div class="list">
        <p class="title">去年同期销售</p>
        <p class="content">¥ {{detail.salesLastYearToday}}</p>
      </div>
      <div class="list">
        <p class="title">销售件数</p>
        <p class="content">{{detail.salesNum}}</p>
      </div>
      <div class="list">
        <p class="title">订单量</p>
        <p class="content">{{detail.orderNum}}</p>
      </div>
      <div class="list">
        <p class="title">客单价</p>
        <p class="content">¥ {{detail.unitPrice}}</p>
      </div>
      <div class="list">
        <p class="title">客件数</p>
        <p class="content">{{detail.unitNum}}</p>
      </div>
      <div class="list">
        <p class="title">提袋率</p>
        <p class="content">{{detail.purchaseRate}}</p>
      </div>
    </div>
    <div class="mark" style="margin-left:12rem;">
      <p class="top">交接事宜</p>
      <div class="handover">
        <p v-if="focosStatu == 0" class="textarea" style="height:310rem;text-align: left;">{{message == ''?'什么也没有留下哦~':message}}</p>
        <textarea v-else class="textarea" ref="focusTextarea" name="" id="" cols="30" rows="10" v-model="message"></textarea>
      </div>
      <p class="edit" @click="editClick()">{{focosStatu == 0 ? '编辑' : '保存'}}</p>
    </div>
  </div>
</template>

<script>
import { getMessage, addMessage, editMessage, infoStatistics } from '../until/user.js'
export default {
  // components: { headers },
  props: {

  },
  data () {
    return {
      focosStatu: 0,
      message: '',
      // 0-新增 1-修改
      type: 1,
      id: '',
      detail: {}
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {
    this.queryMessage()
  },
  methods: {
    editClick () {
      const that = this
      if (that.focosStatu === 0) {
        that.focosStatu = 1
        setTimeout(function () {
          that.$refs.focusTextarea.focus()
        }, 1)
      } else {
        that.focosStatu = 0
        var data = {
          message: that.message,
          id: that.id
        }
        if (that.type === 0) {
          return new Promise((resolve, reject) => {
            addMessage(data)
              .then(response => {
                if (response.data.code === 200) {
                  this.queryMessage()
                } else {
                  that.$message({
                    message: response.data.msg,
                    type: 'warning'
                  })
                  return
                }
                resolve()
              })
              .catch(error => {
                reject(error)
              })
          })
        } else {
          return new Promise((resolve, reject) => {
            editMessage(data)
              .then(response => {
                if (response.data.code === 200) {
                  this.queryMessage()
                } else {
                  that.$message({
                    message: response.data.msg,
                    type: 'warning'
                  })
                  return
                }
                resolve()
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      }
    },
    queryMessage () {
      return new Promise((resolve, reject) => {
        getMessage()
          .then(response => {
            if (response.data.code === 200) {
            // this.memberAddress(this.memberContent.id)
              if (response.data.data === null) {
                this.message = ''
                this.type = 0
              } else {
                this.type = 1
                this.message = response.data.data.message
                this.id = response.data.data.id
              }
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
        infoStatistics()
          .then(response => {
            if (response.data.code === 200) {
              this.detail = response.data.data
            } else {}
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped lang="less">
  .report{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 88rem;
    background: #F7F8FA;
    .mark{
      width: 514rem;
      height: 540rem;
      border: 1rem solid #ECEDEE;
      background: #fff;
      .top{
        height: 54rem;
        background: #F2F3F5;
        text-align: center;
        line-height: 54rem;
        font-size: 18rem;
        color: #333333;
      }
      .list{
        display: flex;
        align-items: center;
        height: 54rem;
        border-top: 1rem solid #E8E9EB;
        .title{
          width: 274rem;
          height: 54rem;
          border-right: 1rem solid #E8E9EB;
          font-size: 18rem;
          color: #333333;
          text-align: center;
          line-height: 54rem;
        }
        .content{
          margin-left: 62rem;
          font-size: 18rem;
          color: #333333;
        }
      }
      .handover{
        margin: 22rem;
        .textarea{
          width: 100%;
          border: none;
          font-size: 18rem;
          color: #333333;
          outline: line !important;
          height: 300rem;
        }
      }
      .edit{
        width: 76rem;
        height: 40rem;
        border-radius: 8rem;
        text-align: center;
        line-height: 40rem;
        background: #F0F2F5;
        font-size: 18rem;
        color: #00281C;
        margin-left: 414rem;
        margin-top: 100rem;
      }
    }
  }
  textarea{
    outline: none;
  }
</style>
